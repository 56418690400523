import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { FaFileAlt } from "react-icons/fa";
import BarraMenu from "../../components/BarraMenu";
import { httpApiPostText } from "../../lib";
import { useNavigate } from "react-router";
import { GET_COMPANY_LOGO } from "../redux/store/Actions";

const ReporteEvaluacionesPage = () => {
  let [data, setData] = useState(new Array());
  let [cpData, setCpData] = useState(new Array());
  const emp: any = useSelector((state: any) => state.emp);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let columns = [
    {
      name: "Id",
      selector: (row: any) => row.id_user,
      sortable: true,
      width: "90px",
      omit: true,
    },
    {
      name: "Nombre",
      selector: (row: any) => row.name,
      sortable: true,
      width: "250px",
    },
    {
      name: "Cliente",
      selector: (row: any) => row.description,
      sortable: true,
      width: "250px",
    },
    {
      name: "Empresa",
      selector: (row: any) => row.namecompany,
      sortable: true,
      width: "250px",
    },
    {
      name: "Fecha",
      selector: (row: any) => {
        console.log("el friking registro es ", row);
        return row.user_evaluation_date
          ? row.user_evaluation_date.substring(0, 10)
          : "";
      },
      sortable: true,
      width: "150px",
    },
    { name: "Acciones", selector: (row: any) => row.Acciones, grow: 3 },
  ];

  const pagOptions = {
    rowsPerPageText: "Filas por páginas",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const filtroUser = async (event: any) => {
    const idUser = event.target.value;

    if (idUser !== "") {
      const text = event.target[event.target.selectedIndex].innerText;

      const req = {
        valid: "getAllEvaluation",
        id_collaborator: idUser,
      };
      const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
      const response = await httpApiPostText(
        url,
        "POST",
        {
          "Content-Type": "application/json",
        },
        req
      );
      const dta = JSON.parse(response.message);

      const newObj: any = [];
      dta.payload.map((dta: any) => {
        const obj = {
          id_user: dta.user_id,
          name: text,
          description: dta.description, ////"Ola K ase?",
          company_logo: dta.logo_image,
          user_evaluation_date: dta.date_close,
          namecompany: dta.namecompany,
          Acciones: (
            <div className=" d-flex gap-1">
              <a
                href="#!"
                id={`${dta.id_user}`}
                onClick={() => {
                  dispatch(GET_COMPANY_LOGO(dta.logo_image));
                  navigate(`/formatoEvaluaciones/${dta.user_id}/${dta.date}`);
                }}
              >
                <FaFileAlt
                  title="Ver evaluación"
                  color={"white"}
                  className="bg-success rounded-circle p-1 h4"
                />
              </a>
            </div>
          ),
        };
        newObj.push(obj);
      });
      setData([...newObj]);
    } else {
      setData([]);
    }
  };

  const preparaDatos = async () => {
    const req = {
      valid: "getCollaboratorsEvaluator",
      evaluatorUser: emp.id_user,
    };
    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "POST",
      {
        "Content-Type": "application/json",
      },
      req
    );

    const dta = JSON.parse(response.message);
    const newData = new Array();
    dta.payload.map((dta: any) => {
      const obj = {
        id_user: dta.id_user,
        name: dta.name,
      };
      newData.push(obj);
    });
    newData.sort((a, b) => (a.name > b.name ? 1 : -1));

    cpData = [...newData];
    setCpData([...cpData]);
  };

  useEffect(() => {
    if (emp.first_name !== "") {
      preparaDatos();
    }
  }, []);

  return (
    <div className="container bg-white">
      <BarraMenu />
      <div>
        <div className="text-secondary ms-3 mt-3 mb-3 fw-bold">
          Home / Reporte / Evaluaciones
        </div>
        <form className=" row ">
          <div className=" col mt-3">
            <div className=" m-2 gap 2 col-lg-6 col-md-12">
              <label htmlFor="evaluado" className="m-1">
                Listado de evaluados
              </label>
              <select
                id="evaluado"
                className="form-select m-1"
                onChange={filtroUser}
              >
                <option value="">Seleccione un evaluado</option>
                {cpData.map((user: any, idx: number) => (
                  <option key={user.id} value={user.id_user}>
                    {user.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            <p className="mt-4">Listado de evaluaciones </p>
          </div>
          <DataTable
            columns={columns}
            data={data}
            selectableRowsHighlight
            fixedHeader
            pagination
            highlightOnHover
            paginationComponentOptions={pagOptions}
            className="border shadow rounded m-3 "
          />
        </form>
      </div>
    </div>
  );
};

export default ReporteEvaluacionesPage;

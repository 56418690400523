import { useState } from "react";
import { Alert, Button, Col, Container, Form } from "react-bootstrap";
import { FaDoorOpen, FaEyeSlash, FaKey, FaRegEye, FaRegHandPaper, FaUserTie } from "react-icons/fa";
import MsgModalSpinner from "../../components/MsgModalSpinner";
import MsgDialog from "../../components/MsgDialog";
import { useNavigate } from "react-router-dom";
import { httpApiPostText } from "../../lib";
import sha256 from "crypto-js/sha256";
import { useDispatch } from "react-redux";
import { SetEntornoEmp } from "../redux/store/Actions";

// Import styles
import "./style.css";

const PageLogin = () => {
  const [msgDlgShow, setMsgDlgShow] = useState(false);
  const [msgAlrtUsr, setMsgAlrtUsr] = useState(false);
  const [msgAlrtPwd, setMsgAlrtPwd] = useState(false);
  const [msgAlrtPwdRegex, setMsgAlrtPwdRegex] = useState(false);
  const [inputText, setInputText] = useState(false);
  const navigate = useNavigate();
  let [formData, setFormData] = useState({
    user: "",
    pass: "",
    valid: "login",
  });
  const dispatch = useDispatch();
  const logo = require("./samtel.jpg");

  const changeText = (evnt: any) => {
    const passRegex = /^(?=.*?[#?!@$%^&*-])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if(evnt.target.id == "pass"){
      if(passRegex.test(evnt.target.value)){
        formData = { ...formData, [evnt.target.id]: evnt.target.value.trim()};
        setFormData(formData);
        setMsgAlrtPwdRegex(false);

      }else{
        formData = { ...formData, [evnt.target.id]: evnt.target.value.trim()};
        setFormData(formData);
        setMsgAlrtPwdRegex(true);
      }
    }else if(evnt.target.id != "pass"){
      formData = { ...formData, [evnt.target.id]: evnt.target.value.trim() };
      setFormData(formData);
    }
  };

  const keyPress = (evnt: any) => {
    if (evnt.key === "Enter") {
      sendForm(evnt);
    }
  };

  const sendForm = async (e: any) => {
    e.preventDefault();
    let sw = 0;
    formData.user === "" ? setMsgAlrtUsr(true) : sw++;
    formData.pass === "" ? setMsgAlrtPwd(true) : sw++;
    const pwdSHA256 = sha256(formData.pass).toString();
    formData = { ...formData, pass: pwdSHA256 };
    if (sw === 2) {
      const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
      const response = await httpApiPostText(
        url,
        "POST",
        {
          "Content-Type": "application/json",
        },
        formData
      );

      const dta = JSON.parse(response.message);
      if (dta.code === 1 || dta.code === 2) {
        dispatch(
          SetEntornoEmp({
            first_name: dta.payload.user.first_name,
            id_user: dta.payload.user.id_user,
            last_name: dta.payload.user.last_name,
            profile: dta.payload.user.profile,
            token: dta.payload.token,
            user: formData.user,
          })
        );
        localStorage.setItem("user", JSON.stringify(dta.payload));

        navigate("/home");
      } else {
        setMsgDlgShow(true);
      }
    }
  };

  /* manejadores de msgDialog */
  const handlerOk = () => {
    setMsgDlgShow(false);
  };

  return (
    <>
      <div className="container w-50 mt-5 shadow-lg border rounded bg-white">
        <img
          src={logo}
          alt=""
          width={200}
          className="img-fluid mt-4 rounded m-auto d-flex"
        />

        <div className="mt-2 text-center">
          <h2>Evaluación del Desempeño</h2>
        </div>

        <Container>
          <div className="m-3 text-center">
            <span className="h4">Inicio de sesión</span>
          </div>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>
                <FaUserTie /> Usuario
              </Form.Label>
              <Form.Control
                type="user"
                placeholder="usuario"
                id="user"
                onChange={changeText}
                value={formData.user}
              />
              <Form.Text>
                <Alert variant="danger" show={msgAlrtUsr} className="p-1 m-0">
                  <FaRegHandPaper className="mb-1" /> Debe ingresar un usuario
                  válido!!!
                </Alert>
                <div className=" text-end ">
                  Ingrese con un usuario autorizado
                </div>
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                <FaKey /> Contraseña
              </Form.Label>
              <div className="block__input-password mb-2">
                <Form.Control
                  type={inputText ? "text" : "password"}
                  placeholder="contraseña"
                  id="pass"
                  onChange={changeText}
                  onKeyUp={keyPress}
                  value={formData.pass}
                />
                {inputText ?
                <FaEyeSlash onClick={() => setInputText(!inputText)} className="mb-1 block__icon" />
                  :
                <FaRegEye onClick={() => setInputText(!inputText)} className="mb-1 block__icon" />
                }
              </div>

              <Form.Text>
                <Alert variant="danger" show={msgAlrtPwd} className="p-1 m-0 mb-1">
                  <FaRegHandPaper className="mb-1" /> Debe ingresar una
                  contraseña válida!!!
                </Alert>
                <Alert variant="danger" show={msgAlrtPwdRegex} className="p-1 m-0">
                  <FaRegHandPaper className="mb-1" /> La contraseña debe cumplir con: Mínimo 8 dígitos, una mayúscula, una minúscula, un carácter especial y un número.
                </Alert>
              </Form.Text>
            </Form.Group>
            <Form.Group className="mt-4 mb-4">
              <Col className="text-center mt-3">
                <Button
                  onClick={sendForm}
                  className="w-50 border "
                  style={{ backgroundColor: " #8FC347", height: "60px" }}
                  //disabled={msgAlrtPwdRegex}
                >
                  <FaDoorOpen /> Ingresar
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Container>
      </div>
      {/*********** seccion de modales **********************/}
      {/*********** cuadro de dialogo para errores **********/}
      <MsgDialog
        Show={msgDlgShow}
        Title={`Inicio de sesión`}
        Message="Usuario y/o password incorrecto o usuario inactivo. Por favor verifiquelo y vuelva a intentar."
        BtnOkName="Aceptar"
        BtnNokName=""
        HandlerClickOk={handlerOk}
        HanlerdClickNok={null}
      />
      <MsgModalSpinner
        Show={false}
        text="Un momento porfavor, validando acceso y cargando valores..."
        color="#FF7588"
      />
    </>
  );
};

export default PageLogin;

import BarraMenu from "../../../components/BarraMenu";
import { Accordion } from "react-bootstrap";
import FormularioPropiedadesPerfiles from "./FormularioPropiedadesPerfiles";
import FormularioPropiedadesClientes from "./FormularioPropiedadesClientes";
import FormularioPropiedadesEstados from "./FormularioPropiedadesEstados";
import FormularioPropiedadesCategories from "./FormularioPropiedadesCategories";
import FormularioPropiedadesUserJob from "./FormularioPropiedadesUserJob";
import FormularioPropiedadesEmpresas from "./FormularioPropiedadesEmpresas";

const AdministracionParametrosPage = () => {
  return (
    <div className="container bg-white">
      <BarraMenu />
      <div>
        <div className="text-secondary ms-3 mt-3 mb-3 fw-bold">
          Home / Administración / Parametros del sistema
        </div>
        <Accordion className="mb-4" defaultActiveKey={["0"]} alwaysOpen>
          <Accordion.Item eventKey="0" className="border shadow ">
            <Accordion.Header>
              <div className=" h5 text-dark">PERFILES</div>
            </Accordion.Header>
            <Accordion.Body>
              <FormularioPropiedadesPerfiles />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion className="mt-4" defaultActiveKey={["0"]} alwaysOpen>
          <Accordion.Item eventKey="0" className="border shadow ">
            <Accordion.Header>
              <div className=" h5 text-dark">CLIENTES</div>
            </Accordion.Header>
            <Accordion.Body>
              <FormularioPropiedadesClientes />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion className="mt-4" defaultActiveKey={["0"]} alwaysOpen>
          <Accordion.Item eventKey="0" className="border shadow ">
            <Accordion.Header>
              <div className=" h5 text-dark">ESTADOS</div>
            </Accordion.Header>
            <Accordion.Body>
              <FormularioPropiedadesEstados />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion className="mt-4" defaultActiveKey={["0"]} alwaysOpen>
          <Accordion.Item eventKey="0" className="border shadow ">
            <Accordion.Header>
              <div className=" h5 text-dark">CATEGORIAS DE EVALUACIONES</div>
            </Accordion.Header>
            <Accordion.Body>
              <FormularioPropiedadesCategories />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion className="mt-4" defaultActiveKey={["0"]} alwaysOpen>
          <Accordion.Item eventKey="0" className="border shadow ">
            <Accordion.Header>
              <div className=" h5 text-dark">EMPRESAS DEL GRUPO</div>
            </Accordion.Header>
            <Accordion.Body>
              <FormularioPropiedadesEmpresas />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion className="mt-4" defaultActiveKey={["0"]} alwaysOpen>
          <Accordion.Item eventKey="0" className="border shadow ">
            <Accordion.Header>
              <div className=" h5 text-dark">CARGOS</div>
            </Accordion.Header>
            <Accordion.Body>
              <FormularioPropiedadesUserJob />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default AdministracionParametrosPage;

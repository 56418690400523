import { useEffect, useState } from "react";
import BarraMenu from "../../components/BarraMenu";
import { httpApiPostText } from "../../lib";
import { useParams } from "react-router";
import RadarChart from "../../components/RadarChart";
import { FaPrint } from "react-icons/fa";
import "./style.css";

const FormatoEvaluacionesPage = () => {
  let [data, setData] = useState(new Array());
  let [cpData, setCpData] = useState(new Array());
  let [distintos, setDistintos] = useState(new Array());
  let [prom, setProm] = useState(new Array().fill(0));
  let [datosRadar, setDatosRadar] = useState(new Array());
  let [categoriesRadar, setCategoriesRadar] = useState(new Array());
  let [nombre, setNombre] = useState("");
  const { id, date } = useParams();

  const distinct = (arr: any): [] => {
    if (arr.length > 0) {
      let arrRes: any = new Array();
      let uniqueCategories: any = {};

      for (const item of arr) {
        const category_id = item.category_id;
        if (!uniqueCategories[category_id]) {
          uniqueCategories[category_id] = true;
          arrRes.push(item.category);
        }
      }

      // Ordenar arrRes por category_id
      arrRes.sort((a: string, b: string) => {
        const categoryA = arr.find((item: any) => item.category === a);
        const categoryB = arr.find((item: any) => item.category === b);
        return categoryA.category_id - categoryB.category_id;
      });

      return arrRes;
    }

    return [];
  };

  const getPreguntas = async () => {
    const req = {
      valid: "dataGraphics",
      id_collaborator: `${id}`,
      date: `${date?.substring(0, 10)}`,
    };

    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "POST",
      {
        "Content-Type": "application/json",
      },
      req
    );

    let dta: any = JSON.parse(response.message);
    dta = dta.payload.sort((objx: any, objy: any) =>
      objx.category.localeCompare(objy.category)
    );
    data = [...dta];
    setData([...data]);
    cpData = [...dta];
    setCpData([...cpData]);

    distintos = [...distinct(cpData)];
    setDistintos(distintos);

    //calcula promedio
    distintos.map((val: any) => {
      let prm = 0;
      let ne = 0;
      let cat: string[] = [];
      let dt: number[] = [];
      cpData
        .filter((dt: any) => dt.category === val)
        .map((qst: any) => {
          if (qst.evaluated_range !== null && qst.evaluated_range !== "") {
            prm += parseFloat(qst.evaluated_range);
          } else {
            prm += 0;
          }
          if (qst.minimum !== qst.maximum) {
            ne++;
            cat.push(qst.title);
            dt.push(qst.evaluated_range);
          }
          setNombre(qst.name);
        });
      prm = prm / ne;
      prom.push(prm);

      categoriesRadar.push([...cat]);
      datosRadar.push([...dt]);
    });
    setProm([...prom]);
    setCategoriesRadar([...categoriesRadar]);
    setDatosRadar([...datosRadar]);
  };

  useEffect(() => {
    getPreguntas();
  }, []);

  return (
    <div className="container-doc bg-white">
      <BarraMenu />
      <div>
        <div className="text-secondary ms-3 mt-3 mb-5 fw-bold">
          Home / Reporte / Formato evaluación
        </div>
        <div className="mt-3">
          <div className="m-2">
            <div className="border rounded p-1 ">
              <div className="d-flex flex-row justify-content-between gap-2">
                <div className="col-lg-3 ">
                  <div className="bg-light">
                    <p className="h6">Colaborador</p>
                  </div>
                  <div>
                    <p className="h6">{nombre} </p>
                  </div>
                </div>

                <div className="col-lg-2">
                  <div className="bg-light w-100">
                    <p className="h6">Fecha</p>
                  </div>
                  <div>
                    <p className="h6">
                      {data[0]?.dateClosing?.substring(0, 10) ||
                        date?.substring(0, 10)}{" "}
                    </p>
                  </div>
                </div>

                <div className="col-lg-2">
                  <div className="bg-light w-100">
                    <p className="h6">Lider </p>
                  </div>
                  <div>
                    <p className="h6">
                      {cpData.length > 0 ? cpData[0].evaluator : ""}{" "}
                    </p>
                  </div>
                </div>

                <div className="col-lg-2">
                  <div className="bg-light">
                    <p className="h6">Cliente</p>
                  </div>
                  <div>
                    <p className="h6">
                      {cpData.length > 0 ? cpData[0].client : ""}
                    </p>
                  </div>
                </div>

                <div className="col-lg-2">
                  <div className="bg-light">
                    <p className="h6">Realizo la evaluacion</p>
                  </div>
                  <div>
                    <p className="h6">
                      {cpData.length > 0 ? cpData[0].make_evaluator : ""}
                    </p>
                  </div>
                </div>

                <div className="col-lg-1">
                  <a
                    href="#!"
                    className=" btn text-light bg-secondary"
                    onClick={() => window.print()}
                  >
                    <FaPrint className=" h3" title="Imprimir" />
                  </a>
                </div>
              </div>
            </div>
            {distintos.map((val: any, idx: number) => (
              <div key={idx} className="mb-4">
                <div className="text-center bg-light border-rounded mb-3 mt-3">
                  <h4>{val} </h4>
                </div>
                <div
                  className="table-responsive"
                  style={{ breakAfter: "page" }}
                >
                  <table
                    className="table  table-striped " /* striped bordered hover */
                  >
                    <thead>
                      <tr className="text-center bg-light m-2">
                        <th>Título</th>
                        <th>Pregunta</th>
                        <th>Valoración</th>
                        <th>Descripción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cpData
                        .filter((dt: any) => dt.category === val)
                        .map((qst: any, idx: number) => (
                          <tr key={idx}>
                            <td key={0}>
                              <div className="d-flex ">
                                <div className="align-middle fw-bold ">
                                  {qst.title}
                                </div>
                              </div>
                            </td>
                            <td
                              key={1}
                              className="text-wrap"
                              style={{ width: "45%" }}
                            >
                              {qst.question}
                            </td>
                            {qst.evaluated_range !== null &&
                            qst.evaluated_range !== "" ? (
                              <td key={2} className="text-center">
                                {parseFloat(qst.evaluated_range).toFixed(2)}
                              </td>
                            ) : (
                              <td key={2} className="text-center">
                                {qst.evaluated_range}
                              </td>
                            )}
                            <td key={3} className="text-wrap">
                              {qst.feedback}
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <td className="fw-bold">Promedio</td>
                        <td></td>
                        <td className="text-center fw-bold">
                          {("" + prom[idx]).substring(0, 4)}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  className="d-flex justify-content-center "
                  style={{ breakAfter: "page" }}
                >
                  <div className="border rounded-3">
                    {datosRadar[idx].some((value: number) => value > 0) ? (
                      <RadarChart
                        seriesName={val}
                        seriesData={datosRadar[idx]}
                        categories={categoriesRadar[idx]}
                      />
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormatoEvaluacionesPage;
